<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="10">
                        <a-button type="primary" @click="createOrEdit('create')" v-if="isGranted('problem_progress_create')">
                            <a-icon type="plus"/>
                            {{l('Create')}}
                        </a-button>
                        <!-- <a-button type="primary" @click="createOrEdit('edit')">
                            <a-icon type="edit"/>
                            修改
                        </a-button> -->
                        <a-button type="danger" @click="Delate()" v-if="isGranted('problem_progress_delete')">
                            <a-icon type="delete"/>
                            {{l('Delete')}}
                        </a-button>
                    </a-col>

                    <a-col :span="14" class="d-flex justify-content-end">
							<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
											style="width: 200px;margin-left: 10px;" @search="filteronchange"

							></a-input-search>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns"
                             :data-source="tableData"
                             :customRow="customRow"
                             :row-selection="{ selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
                             :rowKey="(record)=>record.id"
                             :pagination="false"
                             style="max-height: 600px;overflow: auto;"
                             :scroll="{x:800,y:scroll_x}"
							 size="small"
                    >

                        <span slot="progress" slot-scope="text,record" style="text-align: center">
                            <div>{{record.progress}}%</div>
                        </span>
                        <span slot="foreColor" slot-scope="text,record" style="text-align: center">
                            <div style="color: transparent"
                                 :style="{backgroundColor:record.foreColor}">{{record.foreColor}}</div>
                        </span>
                        <span slot="backColor" slot-scope="text,record" style="text-align: center">
                            <div style="color: transparent"
                                 :style="{backgroundColor:record.backColor}">{{record.backColor}}</div>
                        </span>
						<span slot="action" slot-scope="text,record">
							<a @click.stop="createOrEditMachineSupplier(record)" v-if="isGranted('problem_progress_edit')">
								<a-icon type="edit"/>{{l('Edit')}}
							</a>
							<font v-if="isGranted('problem_progress_delete')&&isGranted('problem_progress_edit')"> | </font>
							<a @click.stop="deletetable(record)" v-if="isGranted('problem_progress_delete')">
								<a-icon type="delete"/>{{l('Delete')}}
							</a>
						</span>
                    </a-table>
                </a-spin>
				<a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
					:show-total="total => `共有 ${totalItems} 条`" @change="onChangePage"
					@showSizeChange="showSizeChange" />
            </a-card>
        </a-config-provider>
    </div>
</template>
<script>
	import { AppComponentBase } from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import { ModalHelper } from '@/shared/helpers';
	import { SceneServiceProxy } from '../../../shared/service-proxies';
	import CreateOrEdit from "./create-edit-scenario/create-edit-scenario";

	let _this;
	export default {
	    name: "scenario",
	    mixins: [AppComponentBase],
	    components: {
	        CreateOrEdit
	    },
	    created() {
	        _this = this;
	        this.SceneServiceProxy = new SceneServiceProxy(this.$apiUrl, this.$api);
	    },
	    mounted() {
	        this.getData()
	    },
	    data() {
	        return {
	            zh_CN,
	            isEdit: false,
	            isLook: false,

	            filterText: undefined,
	            // 总数
	            totalItems: 0,
	            // 当前页码
	            pageNumber: 1,
	            // 共多少页
	            totalPages: 1,
	            // 条数显示范围
	            pagerange: [1, 1],
	            // 显示条数
	            pageSizeOptions: ["10", "20", "50", "100", "500"],
					request: {maxResultCount: 20, skipCount: 0},

					tableData: [],
					
	            columns: [
	                {title: this.l('issueprogress.name'), width: 150, dataIndex: 'name', align: 'center',},
					{},
	                {
	                	title: this.l('Actions'),
	                	width: 150,
	                	dataIndex: 'action',
	                	align: 'center',
	                	scopedSlots: {
	                		customRender: "action"
	                	},
						fixed: 'right',
	                },
	            ],
	            selectedRowKey: [], // 选择的数据Id
	            selectedRow: [],//选择的整行数据
	            isLoading: false,//加载中
	            dictionaryListApi: undefined,
	            EqmamIssueStatusApi: undefined,//接口
				defaultTop:40,
	        }
	    },
	    methods: {
	        createOrEdit(cases) {
	            this.isEdit = false;
	            ModalHelper.create(CreateOrEdit, {
	                isEdit: this.isEdit,
	            }, {width: 500}).subscribe(res => {
	                if (res) {
	                    this.getData();
	                }
	            })
	        },
			createOrEditMachineSupplier(record) {
				this.isEdit = true;
				ModalHelper.create(CreateOrEdit, {
				    isEdit: this.isEdit,
				    id: record.id
				}, {width: 500}).subscribe(res => {
				    if (res) {
				        this.getData();
				    }
				})
			},
	        Delate() {
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}

	            abp.message.confirm(this.l('this_progress_value'), this.l('confirm_deletion'),
	                result => {
	                    if (result) {
	                        this.isLoading = true;
	                        _this.SceneServiceProxy.batchDelete(_this.selectedRowKey).finally(() => {
	                            this.isLoading = false;
	                        }).then(res => {
	                                _this.getData()
	                            }
	                        )
	                    }
	                }
				);

	        },
			//删除
			deletetable(record) {
				this.$confirm(
				    {
				        title: this.l("confirm_deletion"),
				        content: this.l("this_progress_value"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
							let id = [];
							id.push(record.id)
				            this.SceneServiceProxy.batchDelete(id).then(res => {
				                this.getData();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
	        getData() {
				this.selectedRowKey = [];
	            this.isLoading = true;
	            this.SceneServiceProxy.getPaged(
				this.filterText ? this.filterText : undefined,
	            ).finally(() => {
	                this.isLoading = false;
	            }).then(res => {
	                this.tableData = res.items;
					
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
					    res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
					    (this.pageNumber - 1) * this.request.maxResultCount + 1,
					    this.pageNumber * this.request.maxResultCount,
					];
	            })
	        },
	        //行选择
	        customRow: (record) => {
	            return {
	                on: {
	                    click: () => {
	                        _this.onSelect(record)
	                    }
	                }
	            }

	        },
	        //多选
	        SelectAll(record, rows, crows) {
	            if (record) {
	                //更改表格数据勾选状态
	                this.tableData.map(item => {
	                    item.checked = true;
	                });
	                rows.forEach((item, index) => {
	                    //判断是否存在
	                    let u = this.selectedRowKey.find(t => t == item.id);
	                    if (!u) {
	                        this.selectedRowKey = [...this.selectedRowKey, item.id];
	                    }
	                    let a=this.selectedRow.find((x=>x==item));
	                    if(!a){
                            this.selectedRow = [...this.selectedRow, item];
                        }
	                });
	            } else {
	                //更改表格数据勾选状态
	                this.tableData.forEach((item, index) => {
	                    item.checked = false;
	                    this.selectedRowKey = _.difference(this.selectedRowKey, [item.id]);
                        this.selectedRow = _.difference(this.selectedRow, [item]);
	                });
	            }
	        },
	        // 重写复选框点击事件(不可删除)
	        onSelect(record) {
	            //debugger;
	            record.checked = !record.checked;
	            if (record.checked) {
	                this.selectedRowKey.push(record.id);
	                this.selectedRow.push(record);
	            } else {
	                this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.selectedRow = _.difference(_this.selectedRow, [record]);
	            }
	        },

	        //搜索框点击事件
	        filteronchange() {
	            this.pageNumber = 1;
	            this.request.skipCount = 0;
	            this.getData();
	        },
			//分页
			onChangePage(page, pageSize) {
			    this.selectedRowKey = [];
			    this.pageNumber = page;
			    this.request.skipCount = (page - 1) * this.request.maxResultCount;
			    this.getData();
			},
			showSizeChange(current, size) {
			    this.selectedRowKey = [];
			    this.pageNumber = 1;
			    this.request.maxResultCount = size;
			    this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			    this.getData();
			},
	    }
	}
</script>

<style>
	.pagination {
		margin: 10px auto;
		text-align: right;
	}
</style>
