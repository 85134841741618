<template>
    <div>
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <a-config-provider :locale="zh_CN">
            <a-spin :spinning="isLoading" tip="正在创建场景...">
                <a-row v-if="!isEdit">
                    <a-col :span="24">
						<a-col :span="24">
							<a-col :span="8">
							    <a-form-item required :label="l('web场景')">
							    </a-form-item>
							</a-col>
							<a-col :span="9">
							    <a-select showSearch v-model="entity.moudleId"
                                    style="width: 200px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('请选择要复制的web场景')" @change="moudleChange" allow-clear>
                                    <a-select-option v-for="item in MenuList" :key="item.id" :title="item.fullName">
                                        {{ item.fullName }}
                                    </a-select-option>
                                </a-select>
							</a-col>
						</a-col>
                    </a-col>
                </a-row>
                <a-row v-if="!isEdit">
                    <a-col :span="24">
						<a-col :span="24">
							<a-col :span="8">
							    <a-form-item required :label="l('app场景')">
							    </a-form-item>
							</a-col>
							<a-col :span="9">
							    <a-select showSearch v-model="entity.appMoudleId"
                                    style="width: 200px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('请选择要复制的app场景')" @change="appmoudleChange" allow-clear>
                                    <a-select-option v-for="item in appMenuList" :key="item.id" :title="item.fullName">
                                        {{ item.fullName }}
                                    </a-select-option>
                                </a-select>
							</a-col>
						</a-col>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
						<a-col :span="24">
							<a-col :span="8">
							    <a-form-item required :label="l('issueprogress.name')">
							    </a-form-item>
							</a-col>
							<a-col :span="9">
							    <a-input v-model="entity.name" style="width: 200px;"></a-input>
							</a-col>
						</a-col>
                    </a-col>
                </a-row>
            </a-spin>
        </a-config-provider>
        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" :disabled="isdisabled" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { SceneServiceProxy,SceneEditDto } from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'create-edit-scenario',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		data() {
		    return {
		        zh_CN,
		        isEdit: false,
		        id: undefined,
		        isLoading: false,//加载中
				entity: new SceneEditDto(),
                MenuList:[],
                appMenuList:[],
                isdisabled:false
		    }
		},
		created() {
		    _this = this;
		    this.fullData(); // 模态框必须,填充数据到data字段
			this.SceneServiceProxy = new SceneServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
            this.getMenuList();
            this.getappMenuList();
		    this.getData();
            this.entity.moudleId = undefined;
            this.entity.appMoudleId = undefined;
		},
		methods: {

            moudleChange(value) {
                this.entity.moudleId = value;
            },
            appmoudleChange(value) {
                this.entity.appMoudleId = value;
            },

		    save() {
                
                if (this.entity.moudleId == 0 ||this.entity.moudleId == undefined ) {
		            abp.message.info('web场景不可为空');
		            return;
		        }
                if (this.entity.appMoudleId == 0 ||this.entity.appMoudleId == undefined ) {
		            abp.message.info('app场景不可为空');
		            return;
		        }
		        if (!this.entity.name) {
		            abp.message.info('名称不可为空');
		            return;
		        }
                this.isdisabled = true;
                this.isLoading = true;
				this.SceneServiceProxy.createOrUpdate(
				    this.entity
				).finally(() => {
					this.close()
				}).then(res => {
                    this.isLoading = false;
				    this.success(true);
				})
		    },
		    getData() {
		        this.isLoading = true;
		        this.SceneServiceProxy.getForEdit(
		            this.id
		        ).finally(() => {
		            this.isLoading = false;
		        }).then(res => {
		            this.entity = res;
                    this.entity.moudleId = undefined;
		        })
		    },
            getMenuList() {
		        this.isLoading = true;
		        this.SceneServiceProxy.getMenuListForWeb(
		        ).finally(() => {
		            this.isLoading = false;
		        }).then(res => {
		            this.MenuList = res;
		        })
		    },
            getappMenuList() {
		        this.isLoading = true;
		        this.SceneServiceProxy.getMenuListForAPP(
		        ).finally(() => {
		            this.isLoading = false;
		        }).then(res => {
		            this.appMenuList = res;
		        })
		    },
		}
	}
</script>

<style> 

</style>
